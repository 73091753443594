<ng-template #upgradeTemplate>
  This feature is part of <a class="madero-link" href="#" (click)="showUpgradeModal()">{{ requiredPlan }} Plan</a>.
</ng-template>
<streamline-icon
  #popoverDirective="bs-popover"
  [popover]="upgradeTemplate"
  placement="right"
  boundariesElement="window"
  container="body"
  containerClass="upgrade-popover"
  triggers="mouseenter:click"
  [outsideClick]="true"
  class="rise-blue u_clickable"
  [ngClass]="{ 'upgrade-overlay': overlay }"
  name="circleArrowUp"
  width="16"
  height="16"
  (click)="showUpgradeModal()"
/>
