import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    iconRegistry.setDefaultFontSetClass('fa');
  }

  registerIcons() {
    this.iconRegistry.addSvgIconLiteral('exit', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.83337 2.66671C3.65656 2.66671 3.48699 2.73695 3.36197 2.86197C3.23695 2.98699 3.16671 3.15656 3.16671 3.33337V12.6667C3.16671 12.8435 3.23695 13.0131 3.36197 13.1381C3.48699 13.2631 3.65656 13.3334 3.83337 13.3334H6.50004C6.86823 13.3334 7.16671 13.6319 7.16671 14C7.16671 14.3682 6.86823 14.6667 6.50004 14.6667H3.83337C3.30294 14.6667 2.79423 14.456 2.41916 14.0809C2.04409 13.7058 1.83337 13.1971 1.83337 12.6667V3.33337C1.83337 2.80294 2.04409 2.29423 2.41916 1.91916C2.79423 1.54409 3.30294 1.33337 3.83337 1.33337H6.50004C6.86823 1.33337 7.16671 1.63185 7.16671 2.00004C7.16671 2.36823 6.86823 2.66671 6.50004 2.66671H3.83337Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6953 4.19526C10.9556 3.93491 11.3777 3.93491 11.6381 4.19526L14.9714 7.5286C15.2318 7.78894 15.2318 8.21106 14.9714 8.4714L11.6381 11.8047C11.3777 12.0651 10.9556 12.0651 10.6953 11.8047C10.4349 11.5444 10.4349 11.1223 10.6953 10.8619L13.5572 8L10.6953 5.13807C10.4349 4.87772 10.4349 4.45561 10.6953 4.19526Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83337 8.00004C5.83337 7.63185 6.13185 7.33337 6.50004 7.33337H14.5C14.8682 7.33337 15.1667 7.63185 15.1667 8.00004C15.1667 8.36823 14.8682 8.66671 14.5 8.66671H6.50004C6.13185 8.66671 5.83337 8.36823 5.83337 8.00004Z" />
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('pause', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00004 2.66663H4.33337V13.3333H7.00004V2.66663Z" />
        <path d="M12.3333 2.66663H9.66663V13.3333H12.3333V2.66663Z" />
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('play', this.sanitizer.bypassSecurityTrustHtml(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 18 18" id="Button-Play--Streamline-Core.svg" height="20" width="20">
        <desc>Button Play Streamline Icon: https://streamlinehq.com</desc>
        <g id="button-play--button-television-buttons-movies-play-tv-video-controls">
          <path id="Union" fill-rule="evenodd" d="M3.058205714285714 0.021902857142857144c-0.3380114285714286 0 -0.6703657142857143 0.08615714285714285 -0.9657028571428571 0.2502262857142857 -0.30073142857142854 0.1533017142857143 -0.5545714285714286 0.3850239999999999 -0.7345942857142856 0.6707771428571427 -0.18261714285714284 0.28985942857142855 -0.2822902857142857 0.6242365714285714 -0.2881931428571428 0.9667737142857142l-0.0000857142857142857 0V14.1024H1.0695451428571427l0.00017028571428571426 0.009828571428571428c0.005902857142857143 0.3425142857142857 0.105576 0.6769142857142857 0.2881931428571428 0.9667428571428571 0.18002285714285712 0.2858285714285714 0.43386285714285716 0.5174857142857142 0.7345942857142856 0.6708571428571428 0.2953371428571428 0.16399999999999998 0.6276914285714286 0.25017142857142854 0.9657028571428571 0.25017142857142854 0.3428 0 0.679782857142857 -0.08857142857142856 0.97824 -0.25725714285714285 0.006571428571428571 -0.003657142857142857 0.013062857142857142 -0.007542857142857143 0.01947428571428571 -0.011542857142857141l9.862594285714286 -6.099942857142857c0.29908571428571423 -0.15073142857142857 0.5515428571428571 -0.38036571428571425 0.7300571428571428 -0.6643428571428571 0.1841142857142857 -0.2932457142857143 0.2819428571428571 -0.6325142857142857 0.2819428571428571 -0.978822857142857 0 -0.34629714285714286 -0.09782857142857142 -0.6855771428571428 -0.2819428571428571 -0.9788114285714286 -0.17862857142857141 -0.2842742857142857 -0.4315428571428571 -0.5141028571428571 -0.7309714285714285 -0.6648228571428572L4.054297142857142 0.2896742857142857c-0.005885714285714286 -0.0036114285714285714 -0.011839999999999998 -0.007116571428571428 -0.01785142857142857 -0.010513142857142858 -0.2984571428571428 -0.16863862857142856 -0.63544 -0.2572582857142857 -0.97824 -0.2572582857142857Z" clip-rule="evenodd" stroke-width="1"></path>
        </g>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('stop-circle', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3333 8.00004C14.3333 11.6819 11.3486 14.6667 7.66667 14.6667C3.98477 14.6667 1 11.6819 1 8.00004C1 4.31814 3.98477 1.33337 7.66667 1.33337C11.3486 1.33337 14.3333 4.31814 14.3333 8.00004ZM5.66667 6.00004H9.66667V10H5.66667V6.00004Z" />
      </svg>
    `));
  }
}
