import { StatesModule } from '@uirouter/angular';

import { ViewportComponent } from './viewport/viewport.component';
import { ScreenSharingComponent } from './components/screen-sharing/screen-sharing.component';

export const routes: StatesModule = {
  states: [

    {
      name: 'apps.screen-sharing',
      abstract: true,
      component: ViewportComponent
    },
    {
      name: 'apps.screen-sharing.home',
      url: '/join',
      component: ScreenSharingComponent
    }

  ]
};