import { Component, Input, OnInit } from '@angular/core';

import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { DisplaySummaryService } from 'src/app/displays/services/display-summary.service';
import { PlayerLicenseService } from 'src/app/displays/services/player-license.service';

@Component({
  selector: 'display-license-counts',
  templateUrl: './display-license-counts.component.html',
  styleUrls: ['./display-license-counts.component.scss']
})
export class DisplayLicenseCountsComponent implements OnInit {

  @Input() showOnlineCount: boolean;

  get requiredLicenses(): number {
    let required = 0;
    if (this.displaySummaryService.summary && this.displaySummaryService.summary.scheduledUnlicensed) {
      required = this.displaySummaryService.summary.scheduledUnlicensed - this.playerLicenseService.getProAvailableLicenseCount();
    }
    return required;
  }

  constructor(
    public playerLicenseService: PlayerLicenseService,
    public currentPlanService: CurrentPlanService,
    public displaySummaryService: DisplaySummaryService
  ) { }

  ngOnInit(): void {
    this.displaySummaryService.loadSummary();
  }

  purchaseLicenses() {
    if (this.requiredLicenses) {
      this.currentPlanService.showPurchaseOptions(this.requiredLicenses);
    }
  }

}