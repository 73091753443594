import { Component } from '@angular/core';

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import { CompanyAccessService } from '../../services/company-access.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { SelectCompanyComponent } from '../select-company/select-company.component';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'company-buttons',
  templateUrl: './company-buttons.component.html',
  styleUrls: ['./company-buttons.component.scss']
})
export class CompanyButtonsComponent {


  constructor(public userStateService: UserStateService,
    public companyStateService: CompanyStateService,
    public modalService: ModalService,
    public companyAccessService: CompanyAccessService
  ) { }

  selectCompany() {
    return this.modalService.showLargeModal(SelectCompanyComponent, {
      backdrop: true
    });
  }

}

angular.module('risevision.common.components')
  .directive(
    'companyButtons',
    downgradeComponent({
      component: CompanyButtonsComponent
    }) as angular.IDirectiveFactory
  );
