<ng-template #licenseRequired>
  @if (displayService.showLicenseRequired()) {
    <span class="mr-auto">
      <a href="" (click)="confirmLicensing()" [shown]="userStateService.hasRole('da')">License Required</a>
      <span [shown]="!userStateService.hasRole('da')">License Required</span>
    </span>
  }
</ng-template>
<div class="fields-container">
  <div class="row">
    <div class="col-sm-12 col-md-7 pb-2 pb-lg-0">
      <div class="flex-row">
        <stretchy-input name="displayNameInput" ngDefaultControl class="mb-0" [(ngModel)]="displayService.display.name"></stretchy-input>
        <button type="button" id="deleteButton" *requireRole="'da'" [disabled]="!displayService.display.id" (click)="confirmDelete()" class="btn-icon pl-0">
          <streamline-icon class="u_margin-left streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
        </button>
      </div>
    </div>
    <div class="col-sm-12 col-md-5 pl-lg-0">
      <div class="pull-right" [hidden]="!displayService.display || !displayService.display.id">
        <label class="mr-2">Media Player Actions:</label>
        <div class="btn-group dropdown" dropdown>
          <button id="displayActionsButton" type="button" dropdownToggle class="form-control btn-select btn-schedule-selector btn-toolbar flex-row">
            <div class="row-entry">
              <span class="text-gray mr-auto">Choose an action</span>
              <span class="text-gray pl-2"><i class="fa fa-sort"></i></span>
            </div>
          </button>
          <div *dropdownMenu class="dropdown-menu playlist-menu" role="menu" aria-labelledby="displayActionsButton">
            <ul>
              <li>
                <button type="button" class="btn-dropdown u_clickable" [hidden]="displayService.display.onlineStatus === 'not_activated' || !playerProService.isRestartCompatiblePlayer(displayService.display)" [disabled]="!displayService.display.playerVersion" (click)="playerActionsService.confirm(displayService.display.id, displayService.display.name, 'restart')" *requireRole="'da'">
                  Restart Rise Player
                </button>
              </li>
              <li>
                <button type="button" class="btn-dropdown u_clickable"
                  [hidden]="displayService.display.onlineStatus === 'not_activated' || !playerProService.isRebootCompatiblePlayer(displayService.display)" [disabled]="!displayService.display.playerVersion" (click)="playerActionsService.confirm(displayService.display.id, displayService.display.name, 'reboot')" *requireRole="'da'">
                  Reboot Media Player
                </button>
              </li>
              <li>
                <a id="installPlayerAction" class="u_clickable" uiSref="apps.displays.install">
                  <span>Install Display</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- DISPLAY ERRORS AND ALERTS -->
<div [shown]="playerActionsService.controlsError" class="madero-style alert alert-danger u_margin-md-top" role="alert">
  <strong>{{playerActionsService.controlsError}}</strong>
</div>
<div class="fields-preview-panels display-fields-body mt-4" common-header-height>
  <div class="fields-container">
    <div class="scrollable-list border-container">
      <div class="list-group-item">
        <activation-notice></activation-notice>
        <div class="flex-row form-group">
          <div class="row-entry space-between align-end">
            <label class="pull-left mb-3" [shown]="!userStateService.hasRole('cp')">Default Schedule: <span class="font-weight-normal">{{displayService.display.scheduleName || 'None'}}</span></label>
            <preview-selector class="flex-grow" [(selectedItem)]="selectedSchedule" (selectedItemChange)="scheduleSelected()" label="Default Schedule:" additionalClass="mr-auto mb-0" placement="bottom left" [shown]="userStateService.hasRole('cp')"></preview-selector>
            <span class="button-toolbar">
              @if (selectedSchedule) {
                <a id="viewSchedule" class="madero-link u_inline-block my-3 ml-4" uiSref="apps.schedules.details" [uiParams]="{ scheduleId: selectedSchedule.id, cid: selectedSchedule.companyId }">Edit</a>
              }
            </span>
          </div>
        </div>
        @if (displayService.hasOverrideSchedule(displayService.display)) {
          <div class="form-group mb-0">
            <label>Override Schedule{{ displayService.display.overrideScheduleIds.length > 1 ? 's' : '' }}:</label>
            @for (override of displayService.display.overrideSchedules; track override) {
              <div class="flex-row mb-2">
                @if (override) {
                  <div class="row-entry space-between align-end">
                    <div class="flex-grow u_ellipsis">
                      {{ override.name }}
                    </div>
                    <div class="text-danger mx-4">
                      {{ override.scheduleTypeName }}
                    </div>
                    <div>
                      @if (override.companyId !== companyStateService.getSelectedCompanyId()) {
                        <span class="u_link-disabled">Edit</span>
                      }
                      @else {
                        <a class="madero-link u_clickable" uiSref="apps.schedules.details" [uiParams]="{ scheduleId: override.id, cid: companyStateService.getSelectedCompanyId() }">Edit</a>
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
      @if (playerProService.isAndroidPlayer(displayService.display)) {
        <div class="list-group-item">
          <div class="flex-row form-group mb-0">
            <div class="row-entry">
              <label class="control-label pull-left mb-0">Override Alerts:</label>
              <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
              @if (!displayService.showLicenseRequired() && isClassroomAlertsAvailable) {
                <mat-slide-toggle name="toggleOverrideTakeover" [(ngModel)]="displayService.display.overrideTakeoverAllowed" [disabled]="displayService.showLicenseRequired()" id="overrideTakeoverAllowed" />
              }
              @if (!displayService.showLicenseRequired() && !isClassroomAlertsAvailable) {
                <a href="#" class="madero-link" (click)="promptUpgrade('classroom_alerts')">Plan Upgrade Required</a>
              }
            </div>
          </div>
          <div class="mt-3">Allow Rise Player to take over the screen with overrides replace type schedules.</div>
        </div>
      }
      @if(userStateService.hasRole('sa')) {
        <div class="list-group-item">
          <div class="form-group">
            <div class="row-entry font-weight-bold">Licenses:</div>
          </div>
          <div class="flex-row form-group">
            <div class="row-entry">
              <label class="control-label pull-left mb-0">Digital Signage:</label>
              <mat-slide-toggle name="toggleLicense" [(ngModel)]="displayService.display.playerProAuthorized" (change)="displayService.toggleProAuthorized()" [disabled]="!playerLicenseService.isProToggleEnabled(displayService.display)" />
            </div>
          </div>
          @if (displayService.display.isFreeForURLs) {
            <div class="flex-row form-group mt-2">
              <div class="row-entry">
                <label class="control-label pull-left mb-0"></label>
                This is a free display.
              </div>
            </div>
          }
          <div class="flex-row form-group mt-2 mb-0">
            <div class="row-entry">
              <label class="control-label pull-left mb-0">Screen Sharing:</label>
              <mat-slide-toggle name="toggleScreenSharing" [(ngModel)]="displayService.display.screenSharingEnabled" (change)="displayService.toggleScreenSharing()" />
            </div>
          </div>
        </div>
      } @else {
        <div class="list-group-item">
          <div class="flex-row form-group mb-0">
            <div class="row-entry">
              <label class="control-label pull-left mb-0">License:</label>
              <mat-slide-toggle name="toggleLicense" [(ngModel)]="displayService.display.playerProAuthorized" (change)="displayService.toggleProAuthorized()" [disabled]="!playerLicenseService.isProToggleEnabled(displayService.display)" />
            </div>
          </div>
          @if (displayService.display.isFreeForURLs) {
            <div class="flex-row form-group mt-2 mb-0">
              <div class="row-entry">
                <label class="control-label pull-left mb-0"></label>
                This is a free display.
              </div>
            </div>
          }
        </div>
      }
      <div class="list-group-item flex-row form-group mb-0">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Last Connection:</label>
          <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
          @if (!displayService.showLicenseRequired()) {
            <span class="mr-auto">
              {{displayService.display.lastActivityDate | date:'d-MMM-yyyy h:mm a'}}
            </span>
          }
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [hidden]="displayService.display.onlineStatus === 'not_activated'">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Status:</label>
          <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
          @if (!displayService.showLicenseRequired()) {
            <span class="mr-auto">
              <span class="text-danger" [shown]="displayService.display.onlineStatus === 'offline'">Offline</span>
              <span class="text-success" [shown]="displayService.display.onlineStatus === 'online'">Online</span>
            </span>
          }
        </div>
      </div>
      @if (!playerProService.isAvocorDisplay(displayService.display)) {
        <div class="border-top">
          <div class="list-group-item flex-row form-group mb-0">
            <div class="row-entry">
              <label class="control-label pull-left mb-0">
                Monitoring:
              </label>
              <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
              @if (!displayService.showLicenseRequired() && isMonitoringAvailable) {
                <mat-slide-toggle name="toggleMonitoring" [(ngModel)]="displayService.display.monitoringEnabled" (change)="trackDisplayMonitoring()" [disabled]="displayService.showLicenseRequired()" />
              }
              @if (!displayService.showLicenseRequired() && !isMonitoringAvailable) {
                <a href="#" class="madero-link" (click)="promptUpgrade('monitoring')">Plan Upgrade Required</a>
              }
            </div>
          </div>
          <div class="list-group-item" [shown]="!displayService.showLicenseRequired() && displayService.display.monitoringEnabled && isMonitoringAvailable">
            <timeline-basic-textbox [(timelineString)]="displayService.display.monitoringSchedule" [disabled]="!displayService.display.monitoringEnabled"></timeline-basic-textbox>
          </div>
          <div class="list-group-item" [shown]="!displayService.showLicenseRequired() && displayService.display.monitoringEnabled && isMonitoringAvailable">
            <div class="flex-row form-group mb-0">
              <div class="row-entry">
                <label class="control-label pull-left mb-0">Recipients:</label>
                <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
              </div>
            </div>
            @if (!displayService.showLicenseRequired()) {
              <div class="mt-2">Separate multiple recipients by a comma.</div>
              <div class="mt-3" [ngClass]="{ 'has-error': monitoringEmails.errors?.emails }">
                <emails-field #monitoringEmails="ngModel" name="monitoringEmails" ngDefaultControl [(ngModel)]="displayService.display.monitoringEmails"></emails-field>
                <p class="help-block text-danger" [shown]="monitoringEmails.errors?.emails">
                  A valid email address is required.
                </p>
                <p class="help-block text-danger" [shown]="monitoringEmails.errors?.duplicateEmails">
                  The email already exists.
                </p>
              </div>
            }
          </div>
        </div>
      }
      @if (playerProService.isRebootOrRestartDailyCompatiblePlayer(displayService.display)) {
        <div class="list-group-item">
          <div class="flex-row form-group mb-0">
            <div class="row-entry">
              <label class="control-label pull-left
                mb-0">{{playerProService.isRebootCompatiblePlayer(displayService.display)
              ? "Reboot" : "Restart"}} Daily:</label>
              <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
              @if (!displayService.showLicenseRequired()) {
                <mat-slide-toggle name="toggleRestart" [(ngModel)]="displayService.display.restartEnabled" [disabled]="displayService.showLicenseRequired()" id="restartEnabled" />
              }
            </div>
          </div>
          <popup-time-picker id="restartTime" name="restartTime" ngDefaultControl [(ngModel)]="displayService.display.restartTime" [shown]="!displayService.showLicenseRequired() && displayService.display.restartEnabled"></popup-time-picker>
        </div>
      }
      @if (playerProService.isAutostartFlagEnabledPlayer(displayService.display)) {
        <div class="list-group-item">
          <div class="flex-row form-group mb-0">
            <div class="row-entry">
              <label class="control-label pull-left mb-0">Autostart After Boot:</label>
              <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
              @if (!displayService.showLicenseRequired()) {
                <mat-slide-toggle  name="toggleAutostartOnBoot" [(ngModel)]="displayService.display.autostartOnBoot" [disabled]="displayService.showLicenseRequired()" id="autostartOnBoot" />
              }
            </div>
          </div>
        </div>
      }
      @if (playerProService.isDisplayControlCompatiblePlayer(displayService.display)) {
        <div class="list-group-item">
          <div class="flex-row form-group mb-0">
            <div class="row-entry">
              <label class="control-label pull-left mb-0">Display Control:</label>
              <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
              @if (!displayService.showLicenseRequired() && isMonitoringAvailable) {
                <mat-slide-toggle name="toggleDisplayControl" [(ngModel)]="displayService.display.isDisplayControlEnabled" (change)="toggleDisplayControl()" [disabled]="displayService.showLicenseRequired() || !userStateService.hasRole('da')" id="toggleDisplayControl"/>
              }
              @if (!displayService.showLicenseRequired() && !isDisplayControlAvailable) {
                <a href="#" class="madero-link" (click)="promptUpgrade('display_control')">Plan Upgrade Required</a>
              }
            </div>
          </div>
          <div class="mt-2" [shown]="!displayService.showLicenseRequired()">
            Turn screen ON/OFF based on schedule.
          </div>
          @if (playerProService.isGiadaDN74Product(this.displayService.display) && displayService.display.isDisplayControlEnabled) {
            <div class="form-group mt-4">
              <div class="flex-row schedule-type">
                <div class="pull-left pt-2 u_margin-right aligner-item--top text-muted">Modes:</div>
                <div class="row-wrap flex-grow">
                  <div class="schedule-type-value ml-2">
                    <div class="flex-row w-100">
                      <div class="madero-radio mr-4">
                        <input type="radio" id="hdmi-cec" name="hdmiCec" [(ngModel)]="displayControlType" value="hdmi-cec" (ngModelChange)="detectChanges()">
                        <label for="hdmi-cec" class="control-label-secondary">HDMI-CEC</label>
                      </div>
                      <div class="madero-radio mr-4">
                        <input type="radio" id="rs-232" name="rs232" [(ngModel)]="displayControlType" value="rs-232" (ngModelChange)="detectChanges()">
                        <label for="rs-232" class="control-label-secondary">RS-232</label>
                      </div>
                      <span class="mr-auto pt-2 aligner-item--top" *ngIf="displayControlType === 'rs-232' ">
                        <a class="madero-link u_clickable" (click)="configureDisplayControl()" *requireRole="'da'">
                          Configure
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      }
      <div class="list-group-item flex-row form-group mb-0" [shown]="(displayService.display.playerVersion || displayService.display.onlineStatus === 'online') && playerProService.isDeviceOrientationCompatiblePlayer(displayService.display)">
        <div class="row-entry">
          <label class="control-label pull-left mb-0" for="orientation">Rotation:</label>
          <span class="mr-auto">
            <select #orientation name="orientation" class="form-control" [(ngModel)]="displayService.display.orientation">
              <option [ngValue]="0">Landscape</option>
              <option [ngValue]="90">Portrait</option>
              <option [ngValue]="180">Landscape (flipped)</option>
              <option [ngValue]="270">Portrait (flipped)</option>
            </select>
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Use Company Address:</label>
          <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
          @if (!displayService.showLicenseRequired()) {
            <mat-slide-toggle name="toggleUseCompanyAddress" [(ngModel)]="displayService.display.useCompanyAddress" [disabled]="displayService.showLicenseRequired()" id="useCompanyAddress" />
          }
        </div>
      </div>
      <display-address [(display)]="displayService.display"></display-address>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayService.display.playerLocalIpAddress">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">IP Address:</label>
          <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
          @if (!displayService.showLicenseRequired()) {
            <span class="mr-auto">
              {{displayService.display.playerLocalIpAddress}}
            </span>
          }
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayService.display.macAddress">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">MAC Address:</label>
          <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
          @if (!displayService.showLicenseRequired()) {
            <span class="mr-auto">
              {{displayService.display.macAddress}}
            </span>
          }
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayService.display.os">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Operating System:</label>
          <span class="mr-auto">
            {{displayService.display.os}}
            {{playerProService.isFireOSPlayer(displayService.display) ? " (Fire OS)" : ""}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Resolution:</label>
          <span class="mr-auto">
            <span [shown]="displayService.display.playerVersion">{{ getResolution() }}</span>
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayService.display.playerName || displayService.display.playerVersion">
        <div class="row-entry">
          <label class="control-label pull-left mb-0 aligner-item--top">Player:</label>
          <span class="mr-auto">
            <div [shown]="displayService.display.playerName">{{displayService.display.playerName}}</div>
            <div [shown]="displayService.display.playerVersion">{{displayService.display.playerVersion}}</div>
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayService.display.viewerVersion && displayService.display.viewerVersion !== displayService.display.playerVersion">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Viewer:</label>
          <span class="mr-auto">
            {{displayService.display.viewerVersion}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayService.display.browserVersion">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Browser:</label>
          <span class="mr-auto">
            {{(displayService.display.browserName ? displayService.display.browserName + " " : "") + (displayService.display.browserVersion ? displayService.display.browserVersion : "")}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayService.display.serialNumber">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Serial Number:</label>
          <span class="mr-auto">
            {{displayService.display.serialNumber}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayService.display.cpu">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">CPU:</label>
          <span class="mr-auto">
            {{displayService.display.cpu}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayService.display.hostName">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Host Name:</label>
          <span class="mr-auto">
            {{displayService.display.hostName}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayService.display.manufacturer" *requireRole="'sa'">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Manufacturer:</label>
          <span class="mr-auto">
            {{displayService.display.manufacturer}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayService.display.buildType" *requireRole="'sa'">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Build Type:</label>
          <span class="mr-auto">
            {{displayService.display.buildType}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayService.showProductName()">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Product Name:</label>
          <span class="mr-auto">
            {{displayService.display.productName}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayService.display.deviceManagementId">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Device Management ID:</label>
          <span class="mr-auto">
            {{displayService.display.deviceManagementId}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="preview-container">
    @if (isMobile) {
      <div class="align-right mb-4">
        <button class="btn btn-default btn-toolbar" (click)="isMobile = false">Show Preview</button>
      </div>
    }
    @else {
      <div class="screenshot-embed">
        <screenshot (licenseRequired)="confirmLicensing()"></screenshot>
        <div class="form-group my-4">
          <label>Display ID:</label>
          <div>
          For a dedicated <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/115001817143-What-is-a-Media-Player-get-one-pre-configured-build-your-own-?_ga=2.13275188.2099187533.1636965414-1754393580.1635242387" target="_blank">media player</a> connected to your display:<br>
        </div>
        <div class="flex-row space-between mb-3">
          <div class="highlight-text">{{ displayService.display?.id }}</div>
          <a class="u_clickable madero-link" (click)="copyToClipboard(displayService.display.id)">Copy</a>
        </div>
        <div>
          Or, configure a display that can show a <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/4402342244628-How-do-I-install-Rise-Vision-Player-in-a-web-browser-" target="_blank">full screen webpage</a>:
          <a class="u_clickable madero-link u_nowrap" (click)="showWebPlayer=true" [hidden]="showWebPlayer">Show details.</a>
        </div>
        <span [shown]="showWebPlayer">
          <div class="flex-row space-between mt-2">
            <div class="highlight-text">{{ getWebPlayerUrl() }}</div>
            <a class="u_clickable madero-link u_margin-left mb-auto" (click)="copyToClipboard(getWebPlayerUrl())">Copy</a>
          </div>
        </span>
        <div class="text-muted mt-3">
          Each individual device needs to have a unique Display ID.
        </div>
      </div>
    </div>
  }
  </div>
</div>
<div id="errorBox" [shown]="displayService.apiError" class="madero-style alert alert-danger u_margin-md-top" role="alert">
  <strong>{{displayService.errorMessage}}</strong> {{displayService.apiError}}
</div>
