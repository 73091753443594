import { Component, OnDestroy, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ScreenSharingService } from '../../services/screen-sharing.service';
import { CodeInputComponent } from 'angular-code-input';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/components/modals/modal.service';

@Component({
  selector: 'screen-sharing',
  templateUrl: './screen-sharing.component.html',
  styleUrls: ['./screen-sharing.component.scss']
})
export class ScreenSharingComponent implements OnDestroy, OnInit {
  joinCode = '';
  joinedSubscription: Subscription;
  joined: boolean;
  codeLength = 6;
  codeBlank: boolean;
  nameBlank: boolean;
  paused: boolean;
  stopped: boolean;

  @ViewChild('codeInput') codeInput: CodeInputComponent;
  @ViewChild('nameInput') nameInput: ElementRef;

  constructor(
    protected screenSharingService: ScreenSharingService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this._reset();

    this.joinedSubscription = this.screenSharingService.joinedSubject.subscribe((value) => {
      this.joined = value;
      if (!value) {
        setTimeout(() => {
          this.codeInput.focusOnField(0);
        });
      } else {
        this.stopped = !this.screenSharingService.mediaStream;
      }
    });
  }

  _reset() {
    this.stopped = false;
    this.paused = false;
  }

  ngOnDestroy(): void {
    this.screenSharingService.reset();
    this.joinedSubscription.unsubscribe();
  }

  join() {
    this._reset();

    this.codeBlank = this.joinCode.length < this.codeLength;
    this.nameBlank = !this.nameInput?.nativeElement.value;
    if (this.codeBlank) {
      this.codeInput.focusOnField(this.joinCode.length);
    } else if (this.nameBlank) {
      this.nameInput.nativeElement.focus();
    } else {
      this.screenSharingService.join(this.joinCode, this.nameInput.nativeElement.value);
    }
  }

  codeChanged(code: string) {
    this.joinCode = code.toUpperCase();
  }

  codeCompleted(code: string) {
    this.codeBlank = false;
    if (!this.screenSharingService.channelError) {
      this.nameInput.nativeElement.focus();
    }
  }

  start() {
    this.paused = false;
    this.stopped = false;

    this.screenSharingService.start();
  }

  pause() {
    this.paused = true;
    this.stopped = false;

    this.screenSharingService.pause();
  }

  resume() {
    this.paused = false;
    this.stopped = false;

    this.screenSharingService.stream();
  }

  stop() {
    this.stopped = true;
    this.paused = false;

    this.screenSharingService.stop();
  }

  end() {
    this.modalService.confirmDanger('Exit Session?',
      'You will need to enter the session code again to re-enter this room.',
      'Exit Room', 'Cancel'
    ).then(() => {
      this.screenSharingService.reset();
    });
  }
}
