<div class="py-4 align-center">
  <div class="flex-row form-group">
    <div class="row-entry space-between">
      <div class="flex-row">
        <label class="control-label pull-left mb-0">Live Screenshot:</label>
        <div class="text-gray pl-4" [shown]="screenshotState() === 'screenshot-loaded'">
          Last Updated {{ lastModified | date:'d-MMM-yyyy h:mm a' }}
        </div>
      </div>
      <span class="mr-auto" [shown]="screenshotState() === 'no-license'">
        <a href="" (click)="confirmLicensing()" [shown]="userStateService.hasRole('da')">License Required</a>
        <span [shown]="!userStateService.hasRole('da')">License Required</span>
      </span>
      <span id="notActivated" [shown]="screenshotState() !== 'no-license' && displayService.display.onlineStatus === 'not_activated'">
        Not Activated
      </span>
      <span [shown]="reloadScreenshotEnabled() && displayService.display.onlineStatus !== 'not_activated'">
        <!-- online -->
        <a id="btnUpdateScreenshot" class="madero-link u_clickable" (click)="screenshotService.requestScreenshot()">
          <i class="fa fa-refresh icon-left"></i>Refresh
        </a>
      </span>
      <!-- offline, unsupported, out of date, not-installed? -->
      <!-- show nothing -->
    </div>
  </div>
  @if (screenshotState() !== 'screenshot-loaded') {
    <div class="display-screenshot-panel flex-col">
      <div class="display-screenshot">
        @if (!screenshotState()) {
          <div class="display-screenshot-info">
            <p class="text-gray">Screenshot not available.</p>
          </div>
        }
        @if (screenshotState() && screenshotState() !== 'no-license' && displayService.display.onlineStatus === 'not_activated') {
          <div class="display-screenshot-info">
            <p class="text-gray">Display is not activated.</p>
          </div>
        }
        @if (screenshotState() && !(screenshotState() !== 'no-license' && displayService.display.onlineStatus === 'not_activated') && screenshotState() === 'not-supported') {
          <div class="display-screenshot-info">
            <p class="text-gray">This media player does not support screenshots.</p>
          </div>
        }
        @if (screenshotState() === 'no-license') {
          <div class="display-screenshot-info">
            <p class="text-gray">License Required</p>
          </div>
        }
        @if (screenshotState() === 'screenshot-error') {
          <div class="display-screenshot-info">
            <p class="text-gray">There was a problem requesting the screenshot.</p>
          </div>
        }
        @if (screenshotState() === 'loading') {
          <div class="display-screenshot-info">
            <div class="app-loading-dots">
              <i class="fa fa-3x fa-spinner fa-spin fa-fw"></i>
            </div>
          </div>
        }
      </div>
    </div>
  }
  <img class="img-screenshot" [src]="screenshotUrl" [shown]="screenshotState() === 'screenshot-loaded'">
</div>
